import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components";

import Layout from "../components/Layout";
import Section from "../components/Section";
import HeroSection from "../components/HeroSection";

import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";

const validEmail = (mail) => {
  const mailformat = /^(([^<>()[]\.,;:s@"]+(.[^<>()[]\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;

  return true;
}

const TeamMemberHeader = styled.div`
  display: flex;
  color: rgb(91,91,91);
  flex-flow: column wrap;
  align-items: center;
  margin: 2rem auto;
  max-width: 60%;

  .title{
    font-size: 2.6em;
    font-weight: 700;
  }

  .role{
    font-size: 1.5em;
    font-weight: 300;
    text-align: center;
  }

  ul{
    display: inline-block;
    padding: 0;
    margin: 0;
  }

  ul li{
    display: inline-block;
    margin: auto .7em;
    font-size: 1.7em;
  }
`;

export const TeamMemberPageTemplate = props => {
  const {name, contact, bio, role, team, image, helmet} = props;

  return (
    <>
      {helmet || ""}
      <HeroSection
        backgroundImage={image}
      />
      <Section style={{ wrapper: { paddingBottom: 30 } }}>
        {
          <TeamMemberHeader>
            <h1 className="title">{name}</h1>
            <h3 className="role">{role}</h3>
            <ul>
              {
                contact.map( ctc => {
                    return(
                      <li>
                        <a href={ctc.contact.url}><i className={ctc.contact.icon}/></a>
                      </li>
                    )
                  })
              }
            </ul>
            
          </TeamMemberHeader>
        }
        <Section.Body>
        {
          <Markdown>
              {bio}
            </Markdown>
        }
        </Section.Body>
      </Section>
    </>
  )
}

const TeamMember = ({ data }) => {

  const [nodes] = data.allMarkdownRemark.edges;
  const {bio, name, role, team, picture:{publicURL:image}, contacts} = nodes.node.frontmatter;
  const contacto = nodes.node.frontmatter.contacts;
  // const {contact:ctc} = contact;
  // console.log(ctc);

  return(
    <Layout>
      <TeamMemberPageTemplate
        name = {name}
        contact = {contacts}
        bio = {bio}
        role = {role}
        team = {team}
        image = {image}
        helmet = {
          <Helmet titleTemplate="%s | Page">
            <title>{`Team Member`}</title>
            <meta name="description" content={`Team Member`} />
          </Helmet>
        }
      />
    </Layout>
  )
}

export const query = graphql`
  query GetTeamMember($slug: String) {
    allMarkdownRemark(filter: {fields: {slug: {eq: $slug}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            role
            team
            bio
            picture {
              publicURL
            }
            contacts {
              contact {
                icon
                url
              }
            }
          }
        }
      }
    }
  }
`

export default TeamMember
