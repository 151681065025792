import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import Layout from "../../components/Layout";
import HeroSection from "../../components/HeroSection";
import Section from "../../components/Section";

import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";

const TeamWrapper = styled.div`
  margin-bottom: 3rem;
`;

const TeamTitleWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 2rem;

  .title {
    margin-right: 50px;
    font-weight: 300;
    color: #757577;
    font-size: 42px;
  }

  .separator {
    margin: 0;
    width: auto;
    flex-grow: 1;
  }
`;

const MembersWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 30% 30% 30%;
  grid-row-gap: 1rem;
  @media (max-width: 700px) {
    grid-template-columns: 49% 49%;
  }

  @media (max-width: 500px) {
    grid-template-columns: calc(100% - 40px);
    justify-content: center;
  }
`;

const Member = styled(Link)`
  width: 100%;

  color: inherit;
  text-decoration: none !important;

  img {
    object-fit: cover;
    position: center;
  }

  .content {
    padding: 0.5rem 0;
    text-align: center;
  }

  .content * {
    border-bottom-color: red;
  }

  .name {
    color: #38383a;
    font-weight: 600;
    font-size: 28px;

    text-decoration: none;
  }

  .position {
    color: #757577;
    font-weight: 300;
  }
`;

const MemberComponent = member => {
  return (
    <Member to={member.slug}>
      <img src={member.listPictureSrc} alt="" />
      <div className="content">
        <h6 className="name">{member.name}</h6>
        <span className="position">{member.role}</span>
      </div>
    </Member>
  );
};

const getMembersByTeam = members => teamFilter =>
  members
    .filter(({ team }) => team === teamFilter)
    .sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

export const TeamMembersPageTemplate = props => {
  const { title, description, members, titleContent, helmet } = props;
  const membersOfTeam = getMembersByTeam(members);
  return (
    <>
      {helmet || ""}
      <HeroSection
        title={title}
        backgroundColor="#333"
        titleContent={<Markdown>{titleContent}</Markdown>}
      />
      <Section style={{ wrapper: { paddingBottom: 0 } }}>
        {description && <Markdown>{description}</Markdown>}
      </Section>
      <Section>
        <TeamWrapper>
          <TeamTitleWrapper>
            <h5 className="title">Leadership and Engineers</h5>
            <hr className="separator" />
          </TeamTitleWrapper>
          <MembersWrapper>
            {membersOfTeam("leadership").map(member => {
              return <MemberComponent key={member.slug} {...member} />;
            })}
          </MembersWrapper>
        </TeamWrapper>
        <TeamWrapper>
          <TeamTitleWrapper>
            <h5 className="title">Economics Research</h5>
            <hr className="separator" />
          </TeamTitleWrapper>
          <MembersWrapper>
            {membersOfTeam("economics").map(member => {
              return <MemberComponent key={member.slug} {...member} />;
            })}
          </MembersWrapper>
        </TeamWrapper>
        {/*<TeamWrapper>
          <TeamTitleWrapper>
            <h5 className="title">Software Engineering</h5>
            <hr className="separator" />
          </TeamTitleWrapper>
          <MembersWrapper>
            {membersOfTeam("engineering").map(member => {
              return <MemberComponent key={member.slug} {...member} />;
            })}
          </MembersWrapper>
        </TeamWrapper>
          */}
      </Section>
    </>
  );
};

TeamMembersPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const TeamMembersPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter: page },
    teamMembers: { nodes }
  } = data;

  const members = nodes.map(({ fields: { slug }, frontmatter }) => ({
    ...frontmatter,
    slug,
    listPictureSrc: frontmatter.listPicture.childImageSharp.fluid.src
  }));
  const backgroundImage = page.backgroundImage.childImageSharp.fluid.src;
  return (
    <Layout>
      <TeamMembersPageTemplate
        title={page.title}
        titleContent={page.titleContent}
        description={page.description}
        backgroundImage={backgroundImage}
        members={members}
        helmet={
          <Helmet titleTemplate="%s | Page">
            <title>{`${page.title}`}</title>
            <meta name="description" content={`${page.description}`} />
          </Helmet>
        }
      />
    </Layout>
  );
};

TeamMembersPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default TeamMembersPage;

export const TeamMembersPageQuery = graphql`
  query TeamMembersPage {
    markdownRemark(frontmatter: { templateKey: { eq: "our-team-page" } }) {
      frontmatter {
        title
        titleContent
        description
        backgroundImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    teamMembers: allMarkdownRemark(
      sort: { fields: frontmatter___order, order: ASC }
      filter: { frontmatter: { templateKey: { eq: "team-member" } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          name
          team
          role
          listPicture {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          contacts {
            contact {
              icon
              url
            }
          }
          bio
        }
      }
    }
  }
`;
