import React from "react";
import PropTypes from "prop-types";

import Markdown from "markdown-to-jsx";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import HomeStatisticItem from "../components/HomeStatisticItem";
import Section from "../components/Section";
import Accordion from "../components/Accordion";
import GetStartedSection from "../components/GetStartedSection";
import HeroSection from "../components/HeroSection";
import {SolutionItem, CircleIconSolution} from "../templates/index-page"

export const ProgramTemplate = ({
  name,
  backgroundImage,
  description,
  importantFaqSection,
  pathToProsperity,
  helpStarted,
  faqSection,
  learnMoreSection,
  contactUsSection,
  customStyle,
  helmet
}) => {
  return (
    <>
      {helmet || ""}
      {customStyle && <Markdown>{customStyle}</Markdown>}
      <HeroSection backgroundImage={backgroundImage} title={name} description={description} />

      <Section>
        <Section.Body>
          {importantFaqSection.description && (
            <>
              <Markdown>{importantFaqSection.description}</Markdown>
              <br />
              <br />
            </>
          )}
          {importantFaqSection.questions && (
            <Accordion
              items={importantFaqSection.questions.map(
                ({ question, answer }, index) => ({
                  head: (
                    <strong>
                      <big style={{ fontSize: "120%" }}>{question}</big>
                    </strong>
                  ),
                  body: <Markdown>{answer}</Markdown>
                })
              )}
            />
          )}
        </Section.Body>
      </Section>

      {pathToProsperity &&
        <Section
          theme="dark"
          title={pathToProsperity.title}
          subtitle={pathToProsperity.subtitle}
          styl={{
            h: `
              h2 {
                font-weight: 700 !important;
              }
            `
          }}
        >
          <Section.Body>
            {pathToProsperity.prosperityBenefits && pathToProsperity.prosperityBenefits.map((benefit, index) => {
              return (
                <SolutionItem key={index}>
                  <CircleIconSolution>{index + 1}</CircleIconSolution>
                  <div className="content">
                    <h4 className="title">{benefit.name}</h4>
                    <div className="description">
                      <Markdown>{benefit.description}</Markdown>
                    </div>
                  </div>
                </SolutionItem>
              );
            })}
            <HomeStatisticItem.Divider
              style={{
                position: "absolute",
                bottom: 0,
                maxWidth: "1062px",
                left: "50%",
                transform: "translate3d(-50%,-50%,0)"
              }}
            />
          </Section.Body>
        </Section>
      }
      {
        helpStarted &&
        <Section
          title={helpStarted.title}
          subtitle={helpStarted.subtitle}
          styl={{
            h: `
              h2 {
                font-weight: 700 !important;
              }
            `
          }}
        >
          <Section.Body>
            {helpStarted.listToGetStarted.map((todo, index) => {
              return (
                <SolutionItem key={index}>
                  <CircleIconSolution>{index + 1}</CircleIconSolution>
                  <div className="content">
                    <h4 className="title">{todo.name}</h4>
                    <div className="description">
                      <Markdown>{todo.description}</Markdown>
                    </div>
                  </div>
                </SolutionItem>
              );
            })}
            <HomeStatisticItem.Divider
              style={{
                position: "absolute",
                bottom: 0,
                maxWidth: "1062px",
                left: "50%",
                transform: "translate3d(-50%,-50%,0)"
              }}
            />
          </Section.Body>
        </Section>
      }
      <GetStartedSection />
      <Section
        title={faqSection.title}
        subtitle={faqSection.subtitle || "— Get all of your questions answered"}
        style={{ wrapper: { paddingBottom: "0" } }}
      >
        <Section.Body>
          {faqSection.body && (
            <>
              <Markdown>{faqSection.body}</Markdown>
              <br />
              <br />
            </>
          )}
          {faqSection.questions && (
            <Accordion
              items={faqSection.questions.map(
                ({ question, answer }, index) => ({
                  head: (
                    <strong>
                      <big style={{ fontSize: "110%" }}>{question}</big>
                    </strong>
                  ),
                  body: <Markdown>{answer}</Markdown>
                })
              )}
            />
          )}
        </Section.Body>
      </Section>
      <Section
        title={learnMoreSection.title}
        subtitle={
          learnMoreSection.subtitle || "— Get all of your questions answered"
        }
      >
        <Section.Body>
          {learnMoreSection.body && (
            <>
              <Markdown>{learnMoreSection.body}</Markdown>
              <br />
              <br />
            </>
          )}
          {learnMoreSection.questions && (
            <Accordion
              items={learnMoreSection.questions.map(
                ({ question, answer }, index) => ({
                  head: (
                    <strong>
                      <big style={{ fontSize: "110%" }}>{question}</big>
                    </strong>
                  ),
                  body: <Markdown>{answer}</Markdown>
                })
              )}
            />
          )}
        </Section.Body>
      </Section>
      <Section
        theme="dark"
        title={contactUsSection.title || "Contact Us"}
        subtitle={
          contactUsSection.subtitle || "— Do you have additional questions?"
        }
      >
        <Section.Body>
          {contactUsSection.body && (
            <Markdown>{contactUsSection.body}</Markdown>
          )}
        </Section.Body>
      </Section>
    </>
  );
};

const Program = ({ data }) => {
  const {
    page: { frontmatter: program }
  } = data;
  // const backgroundImage = program.backgroundImage.childImageSharp.fluid.src;
  const backgroundImage = program.backgroundImage.publicURL;
  return (
    <Layout>
      <ProgramTemplate
        name={program.name}
        customStyle={program.customStyle}
        backgroundImage={backgroundImage}
        description={program.description}
        importantFaqSection={program.importantFaqSection}
        pathToProsperity={program.pathToProsperity}
        helpStarted={program.helpStarted}
        faqSection={program.faqSection}
        learnMoreSection={program.learnMoreSection}
        contactUsSection={program.contactUsSection}
        customStyle={program.customStyle}
        helmet={
          <Helmet titleTemplate="%s | Program">
            <title>{`${program.name}`}</title>
            <meta
              name="description"
              content={`${program.importantFaqSection.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  );
};

Program.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default Program;

export const pageQuery = graphql`
  query ProgramPageById($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        backgroundImage {
          publicURL
          # childImageSharp {
          #   fluid(maxWidth: 1200, quality: 100) {
          #     ...GatsbyImageSharpFluid
          #   }
          # }
        }
        description
        importantFaqSection {
          description
          questions {
            question
            answer
          }
        }
        pathToProsperity {
          title
          subtitle
          prosperityBenefits {
            name
            description
          }
        }
        helpStarted {
          title
          subtitle
          listToGetStarted {
            name
            description
          }
        }
        faqSection {
          title
          subtitle
          body
          questions {
            answer
            question
          }
        }

        learnMoreSection {
          title
          subtitle
          body
          questions {
            question
            answer
          }
        }

        contactUsSection {
          title
          subtitle
          body
        }
        customStyle
      }
    }
  }
`;
