import React from "react";
import styled from "styled-components";

import Container from "./Container";

const HeroContent = styled.section`
  position: ${({ isFixed }) => (isFixed ? "absolute" : "inherit")};
  left: 50%;

  top: 0;
  padding: 4rem 0;
  max-width: 1200px;
  transform: translate3d(-50%, 0, 0);
  width: 100%;
  height: 100%;

  @media (max-width: 1062px) {
    padding: 1rem;
  }
`;

const HeroTitle = styled.h1`
  background-color: #32558f;
  color: #fff;
  padding: 0.5rem 2rem;
  display: inline-flex;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 400;

  @media (max-width: 760px) {
    font-size: 4vw;
    padding: 0.5rem 1rem;
  }
`;

const HeroDescription = styled.h1`
  color: #fff;
  font-size: 52px;
  font-weight: 800;
  left: 0%;
  margin: 0 auto;
  padding: 0.5rem 2rem;
  position: absolute;
  top: 30%;
  right: 0%;
  width: 90%;

  @media (max-width: 760px) {
    font-size: 4vw;
    padding: 0.5rem 1rem;
  }
`;

const BackgroundImage = styled.img`
  object-fit: contain;
  width: 100%;
`;

const Wrapper = styled.section`
  position: relative;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "auto"};
  /* display: flex;
  min-height: 545px;
  justify-content: center;
  align-items: center; */
`;

const ContentText = styled.div`
  padding-top: 4rem;
  padding-bottom: 3rem;
  color: #fff;

  h4 {
    font-size: 56px;
    line-height: 68px;
    font-weight: 600;
  }

  @media (max-width: 760px) {
    h4 {
      font-size: 32px;
      line-height: 48px;
    }
    padding-bottom: 3rem;
  }
`;

const HeroSection = ({
  className,
  backgroundImage = null,
  backgroundColor,
  titleContent,
  title = null,
  description
}) => {
  return (
    <Wrapper className={className} backgroundColor={backgroundColor}>
      {backgroundImage && <BackgroundImage src={backgroundImage} />}
      <HeroContent isFixed={!!backgroundImage}>
        {title && (<HeroTitle className="program-title-inner hero-title">
          {title}
        </HeroTitle>)}
        <HeroDescription>
          {description}
        </HeroDescription>
        {titleContent && (
          <ContentText>
            <Container>{titleContent}</Container>
          </ContentText>
        )}
      </HeroContent>
    </Wrapper>
  );
};

export default HeroSection;
